import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  lockIcon,
  messageIcon,
  signImage,
} from '../../constants/imageConstants';
import AuthWrapper from './AuthWrapper';
import { ROUTER_URL_CONSTANT } from '../../constants/routerUriConstants';
import MainLinkButton from '../../components/buttons/MainLinkButton';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { SystemState } from '../../store/storeTypes';
import { AnyAction } from 'redux';
import { encryptPassword } from './helper/encryption';
import { useSelector } from 'react-redux';
import { signinSelectors } from '../../store/auth/signin';
import { signinPartner } from '../../services/auth/signinPartner';
import api from '../../api';
import { encrypt } from '../../crypto';
import { toast } from 'react-toastify';

type Login = {
  email: string;
  password: string;
};

const schema = yup.object({
  email: yup.string().email().required('Email ID cannot be empty'),
  password: yup.string().required(),
})

const Signin = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<Login>({
    resolver: yupResolver(schema),
  });

  const [keepSignin, setKeepSignin] = useState(false);

  // const dispatch =
  //   useDispatch<ThunkDispatch<SystemState, unknown, AnyAction>>();

  const navigate = useNavigate();

  const apiRequest = async (params) => {
    const { data } = await api.post('/user/noAuth/login', params)
    // console.log(data);
    if (data.status === 'SUCCESS') {
      const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: '/user/auth/getUserInfo',
        headers: {
          'Authorization': 'Bearer ' + data.data,
        },
      };

      let { data: userData } = await api(config);
      userData = userData.data;
      localStorage.setItem('token', data.data);
      localStorage.setItem('userData', encrypt(JSON.stringify(userData)));

      navigate('/profile/details');

    } else if (data.statusMessage === 'Invalid password to  login') {
      toast.error('Email or password is wrong', {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    else {
      toast.error('Something went wrong', {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }

  return (
    <>
      <AuthWrapper>
        <div className="welcome">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="welcome-left">
                  <img src={signImage} className="img-fluid" alt="Title Icon" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="login-left">
                  <h2>
                    Welcome Back
                    <span className="d-block"> Sign in to Continue </span>
                  </h2>
                  <p>Use your registered Email ID or Mobile Number</p>
                  <div className="welcome-form">
                    <form
                      onSubmit={handleSubmit((d) => {
                        const params = {
                          userName: d.email,
                          password: encrypt(d.password),
                          userType: '6',
                        };
                        apiRequest(params);
                      })}
                    >
                      <div className="col-md-12 frmss">
                        <img
                          src={messageIcon}
                          className="img-fluid"
                          alt="icon"
                        />
                        <input
                          type="email"
                          className="form-control form-padding"
                          placeholder="Email *"
                          {...register('email')}
                        />
                        {errors.email && (
                          <p className="error">{errors.email.message}</p>
                        )}
                      </div>

                      <div className="col-md-12 frmss">
                        <img src={lockIcon} className="img-fluid" alt="icon" />
                        <input
                          type="password"
                          className="form-control form-padding"
                          placeholder="Password *"
                          {...register('password')}
                        />
                        {errors.password && (
                          <p className="error">{errors.password.message}</p>
                        )}
                      </div>

                      {/* <div className="col-md-12 frmss">
                        <p className="forget">
                          <a href="forgot_password.html">Forgot Password?</a>{' '}
                        </p>
                      </div> */}

                      <div className="col-md-12 frmss mb-2">
                        <input
                          id="checkbox"
                          type="checkbox"
                          style={{ marginRight: '5px', cursor: 'pointer' }}
                          onChange={(e) => setKeepSignin(e.target.checked)}
                        />
                        <label htmlFor="checkbox">
                          Keep me signed in.{' '}
                          <Link
                            to="#"
                            title="Choosing ‘Keep me signed in’  checkbox, reduces the number of times you are asked to sign in. We recommend to use this option when you are working on personal device."
                          >
                            Details
                          </Link>
                        </label>
                      </div>
                      <div className="col-md-12 frmss mb-3">
                        <label htmlFor="checkbox">Sign In with OTP</label>
                      </div>

                      <MainLinkButton
                        isActive={
                          !errors.email?.message && !errors.password?.message
                        }
                        title="Enter"
                      />

                      <div className="col-md-12 frmss">
                        <h6 className="btnlogin1">
                          Already have an account?
                          <Link to={ROUTER_URL_CONSTANT.SIGNUP}> Sign Up </Link>
                        </h6>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AuthWrapper>
    </>
  );
};

export default Signin;
