import React, { useEffect, useState } from 'react';
import {
  downFlower,
  logoImage,
  upFlower,
} from '../../constants/imageConstants';
import { Link, useNavigate } from 'react-router-dom';
import MainLinkButton from '../../components/buttons/MainLinkButton';
import {
  APP_CONSTANTS,
  ROUTER_URL_CONSTANT,
} from '../../constants/routerUriConstants';
import OTPInput from 'react-otp-input';
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { SystemState } from '../../store/storeTypes';
import { AnyAction } from 'redux';
import { partnerMobileVerify } from '../../services/auth/mobileVerify';
import { sendVerificationOtp } from '../../services/auth/signupPartner';
import { getItem } from '../../utils/storage';
import { decrypt } from '../../crypto';
import api from '../../api';
import { toast } from 'react-toastify';

const MobileVerify = () => {
  const [otp, setOtp] = useState('');
  const [resend, setResend] = useState(false);
  const [resentActive, setResentActive] = useState(60);
  const [userId, setUserId] = useState(null);

  const handleChange = (value: string) => {
    setOtp(value);
  };

  // const dispatch =
  // useDispatch<ThunkDispatch<SystemState, unknown, AnyAction>>();

  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      setResentActive((timer) => (timer > 0 ? timer - 1 : timer));
    }, 1000);
    return () => clearInterval(interval);
  }, [resend]);

  const sendOtp = () => {
    const userData = JSON.parse(decrypt(localStorage.getItem('userData')));
    console.log(userData);
    const { id: uid, userCode: userName } = userData;
    setUserId(uid);
    const params = {
      partnerType: "admin",
      userName,
      userType: "6",
    };

    api.post('/user/noAuth/sendOTPForLogin', params)
      .then(res => {
        res = res.data;
        console.log(res);
        if (res.status === 'SUCCESS') {
          toast.success('OTP sent successfully', {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else {
          toast.error("Can't sent OTP", {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          console.log(res);
        }
      })
  }

  useEffect(() => {
    if (localStorage.getItem('userData')) {
      sendOtp();
    } else {
      window.location.assign('/signin');
    }
  }, [])

  return (
    <>
      <div className="flogs2">
        <img src={downFlower} alt="Flower Background" />
      </div>
      <div className="flogs1">
        <img src={upFlower} alt="Flower Background" />
      </div>
      <div className="logo">
        <div className="row">
          <div className="col-md-12">
            <div className="log">
              <Link to="/">
                <img src={logoImage} className="img-fluid" alt="Brand Logo" />{' '}
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="clearfix"></div>
      <div className="mob pt-md-5 pb-md-5 ">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="login-left lts">
                <h2>Mobile Number Verification</h2>
                {/* <p>
                  Enter OTP sent to +91 *******111 <Link to=""> Change</Link>
                </p> */}
                <div className="welcome-form">
                  <form
                    onSubmit={async (e) => {
                      e.preventDefault();
                      // dispatch(partnerMobileVerify(otp, navigate));
                      const { data } = await api.get(`/user/userVerification/phone/${otp}/${userId}`);
                      console.log(data);
                      if (data.statusCode == 200) {
                        // alert('OTP verified successfully');
                        navigate('/mobileverified');
                      } else {
                        toast.error('Invalid OTP', {
                          position: "top-right",
                          autoClose: 1500,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          theme: "light",
                        });
                      }
                    }}
                  >
                    <div className="col-md-12 frmss">
                      <OTPInput
                        value={otp}
                        onChange={handleChange}
                        numInputs={4}
                        className="frmotp otp-input"
                        separator={<span style={{ width: '10px' }} />}
                        isInputNum={true}
                        containerStyle={{
                          justifyContent: 'center',
                        }}
                      />
                    </div>
                    <div className="col-md-12 frmss mb-2">
                      <label htmlFor="checkbox">
                        Code expires in 00:{resentActive}
                      </label>
                    </div>
                    <div
                      className="col-md-12 frmss mb-3"
                      onClick={() => {
                        const phoneNumber = getItem(APP_CONSTANTS.USER);
                        const otpParams = {
                          partnerType: 'admin',
                          userName: phoneNumber,
                          userType: '6',
                        };

                        if (resentActive <= 0) {
                          setResend(!resend);
                          setResentActive(60);
                          // dispatch(sendVerificationOtp(otpParams));
                        }
                      }}
                    >
                      <p
                        className="resend"
                        style={
                          resentActive > 0 ? { opacity: 0.6 } : { opacity: 1 }
                        }
                      >
                        <a onClick={sendOtp}>Resend OTP</a>
                      </p>
                    </div>

                    <MainLinkButton title="Confirm" />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileVerify;
