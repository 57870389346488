export const GET_USER_DATA = 'GET_USER_DATA';

// PERSONAL DETAILS
export const PROFILE_ACTION = 'PROFILE_ACTION';
export const GET_PROFILE = 'GET_PROFILE';
export const SAVE_PROFILE_INFO = 'SAVE_PROFILE_INFO';
export const GET_PROFILE_FAILED = 'GET_PROFILE_FAILED';

export const SAVE_BUSINESS_INFO = 'SAVE_BUSINESS_INFO';
export const SAVE_BUSINESS_INFO_FAILED = 'SAVE_BUSINESS_INFO_FAILED';
export const GET_BUSINESS_INFO = 'GET_BUSINESS_INFO';

export const SAVE_BANK_DETAILS = 'SAVE_BANK_DETAILS';
export const SAVE_BANK_DETAILS_FAILED = 'SAVE_BANK_DETAILS_FAILED';

export const UPLOAD_BANK_DETAILS = 'UPLOAD_BANK_DETAILS';

// ====== GSTIN ======
export const SAVE_GSTIN_DETAILS = 'SAVE_GSTIN_DETAILS';
