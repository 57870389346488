export const logoImage = require('../assets/img/logo.png');
export const signImage = require('../assets/img/sign.png');
export const userIcon = require('../assets/img/user.png');
export const messageIcon = require('../assets/img/msg.png');
export const callIcon = require('../assets/img/call.png');
export const lockIcon = require('../assets/img/lock.png');
export const downFlower = require('../assets/img/downflower.png');
export const upFlower = require('../assets/img/upflower.png');
export const flowerImage = require('../assets/img/flower.png');
export const tickIcon = require('../assets/img/tick.png');
export const profileImage = require('../assets/img/ab.png');
export const bellIcon = require('../assets/img/bell.png');
export const dashboardIcon = require('../assets/img/Dashboard.png');
export const profileIcon = require('../assets/img/profile.png');
export const orderIcon = require('../assets/img/order.png');
export const returnIcon = require('../assets/img/return.png');
export const inventoryIcon = require('../assets/img/inventory.png');
export const paymentsIcon = require('../assets/img/payments.png');
export const catelogueIcon = require('../assets/img/catalogue.png');
export const reportIcon = require('../assets/img/report.png');
export const advertIcon = require('../assets/img/advert.png');
export const supportIcon = require('../assets/img/support.png');
export const tickTopIcon = require('../assets/img/tick-top.png');
export const deletesIcon = require('../assets/img/deletes.png');
export const avatarActiveIcon = require('../assets/img/avt.png');
export const bankIcon = require('../assets/img/museum1.png');
export const editPropertyIcon = require('../assets/img/Edit Property1.png');
export const multiUserIcon = require('../assets/img/User Account1.png');
export const secureIcon = require('../assets/img/Secure1.png');
export const policyIcon = require('../assets/img/Policy Document1.png');
export const notificationIcon = require('../assets/img/Notification1.png');
export const editIcon = require('../assets/img/Edit.png');
export const deleteIcon = require('../assets/img/Delete.png');

export const sortIcon = require('../assets/img/sort 4.png');

export const invtImage = require('../assets/img/invntimg.png');
export const paymentFilterIcon = require('../assets/img/paymentfilter.png');

export const activeRatingIcon = require('../assets/img/stars1.png');
export const desableRatingIcon = require('../assets/img/stars2.png');

export const infoIcon = require('../assets/img/Info.png');

export const catalogueFds = require('../assets/img/cat-fds.png');
