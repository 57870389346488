export const ROUTER_URL_CONSTANT = {
  ABOUT: '/about',
  SIGNUP: '/signup',
  SIGNIN: '/signin',
  MOBILEOTP_VERIFY: '/mobileotp',
  MOBILEOTP_VERIFIED: '/mobileverified',
  HOME: '/',
  PROFILE: 'profile',
  PROFILE_DETAILS: 'details',
  BANK_DETAILS: 'bank',
  GSTIN_DETAILS: 'gstin',
  MANAGE_USERS: 'users',
  CHANGE_PASSWORD: 'password',
  POLICIES: 'policies',
  WHATSAPP_NOTIFICATION: 'notification',
  INVENTORY: 'inventory',
  INVENTORY_ACTIVE: 'active',
  INVENTORY_PENDING: 'pending',
  INVENTORY_INACTIVE: 'inactive',
  INVENTORY_ONHOLD: 'onhold',
  INVENTORY_BLOCKED: 'blocked',

  //   PAYMENTS ROUTES
  PAYMENYS: 'payments',

  // ORDERS ROUTE
  ORDERS: 'orders',
  NEW_ORDER: 'neworders',
  READY_TO_SHIP: 'readytoship',
  INTRANSIT: 'intransit',
  DELIVERED: 'delivered',
  CANCELLED: 'cancelled',

  // REPORTS ROUTE
  REPORTS: 'reports',
  REPORT_ORDERS: 'reportorders',
  REPORT_RETURNS: 'returns',
  REPORT_REVIEWS_RATINGS: 'reportratings',
  REPORT_CATEGORY_WISE: 'catreport',

  // CATELOG ROUTE
  CATELOGS: 'catelogs',
  CATALOG_SELECT_CATEGORY: 'catlogcategory',
  CATALOGUE_PRODUCT: 'catproduct',
  CATALOGUE_PRODUCT_INFO: 'info',
  CATALOGUE_PRICING: 'pricing',
  CATALOGUE_DESCRIPTION: 'description',
  CATALOGUE_IMAGES_VIDEOS: 'images',
  CATALOGUE_VARIATIONS: 'variations',

  // RETURN ROUTE
  RETURN: 'return',
  INITIATED: 'initiated',
  TODAY: 'today',
  COMPLETED: 'completed',
  EXCHANGE: 'exchange',
  EMAILVERIFY: '/email-verify',
  EMAILVERIFIED: '/email-verified',
};

export const APP_CONSTANTS = {
  OTP_EXP_TIME: 60,
  auth_token: 'token',
  USER_SESSION: 'userData',
  USER: 'phone',
  OTP_SESSION: 'otp_session',
};
