import React from 'react';

const CatalogueDescription = () => {
  return (
    <div className="cater_forms pb-5">
      <form>
        <div className="form-group row">
          <div className="col-md-6 cts_frmses">
            <label htmlFor="staticEmail" className="col-form-label">
              <i className="fa fa-info-circle" aria-hidden="true"></i>
              Brief Desccription <span> * </span>
            </label>
            <input type="text" className="form-control" name="" />
          </div>
        </div>

        <div className="form-group row">
          <div className="col-md-6 cts_frmses">
            <label htmlFor="staticEmail" className="col-form-label">
              <i className="fa fa-info-circle" aria-hidden="true"></i>
              Highlights <span> * </span>
            </label>
            <input type="text" className="form-control" name="" />
          </div>
        </div>

        <div className="form-group row">
          <div className="col-md-6 cts_frmses">
            <label htmlFor="staticEmail" className="col-form-label">
              <i className="fa fa-info-circle" aria-hidden="true"></i>
              Keywords <span> * </span>
            </label>
            <input type="text" className="form-control" name="" />
          </div>
        </div>

        <div className="form-group row">
          <div className="col-md-6 cts_frmses">
            <label htmlFor="staticEmail" className="col-form-label">
              <i className="fa fa-info-circle" aria-hidden="true"></i>
              Related faiths <span> * </span>
            </label>
            <input type="text" className="form-control" name="" />
          </div>
        </div>

        <div className="form-group row">
          <div className="col-md-6 cts_frmses">
            <label htmlFor="staticEmail" className="col-form-label">
              <i className="fa fa-info-circle" aria-hidden="true"></i>
              Related Festival <span> * </span>
            </label>
            <input type="text" className="form-control" name="" />
          </div>
        </div>

        <div className="form-group row">
          <div className="col-md-6 cts_frmses">
            <label htmlFor="staticEmail" className="col-form-label">
              <i className="fa fa-info-circle" aria-hidden="true"></i>
              Related Deities <span> * </span>
            </label>
            <input type="text" className="form-control" name="" />
          </div>
        </div>

        <div className="form-group row">
          <div className="col-md-6 cts_frmses">
            <label htmlFor="staticEmail" className="col-form-label">
              <i className="fa fa-info-circle" aria-hidden="true"></i>
              Other Information <span> * </span>
            </label>
            <input type="text" className="form-control" name="" />
          </div>
        </div>

        <div className="form-group row">
          <div className="col-md-6 cts_frmses">
            <label htmlFor="staticEmail" className="col-form-label">
              <i className="fa fa-info-circle" aria-hidden="true"></i>
              Form of product <span> * </span>
            </label>
            <select name="service" id="cars" className="form-control">
              <option value="">Select</option>
              <option value="">Most Recent</option>
              <option value="">Last Week</option>
              <option value="">Last Month</option>
              <option value="">Last Year</option>
            </select>
          </div>
        </div>

        <div className="form-group row">
          <div className="col-md-6 cts_frmses">
            <label htmlFor="staticEmail" className="col-form-label">
              <i className="fa fa-info-circle" aria-hidden="true"></i>
              Perishable/Expirable <span> * </span>
            </label>
            <select name="service" id="cars" className="form-control">
              <option value="">Select</option>
              <option value="">Most Recent</option>
              <option value="">Last Week</option>
              <option value="">Last Month</option>
              <option value="">Last Year</option>
            </select>
          </div>
        </div>

        <div className="form-group row">
          <div className="col-md-6 cts_frmses">
            <label htmlFor="staticEmail" className="col-form-label">
              <i className="fa fa-info-circle" aria-hidden="true"></i>
              Shelf Life <span> * </span>
            </label>
            <select name="service" id="cars" className="form-control">
              <option value="">Select</option>
              <option value="">Most Recent</option>
              <option value="">Last Week</option>
              <option value="">Last Month</option>
              <option value="">Last Year</option>
            </select>
          </div>
        </div>

        <div className="form-group row">
          <div className="col-md-6 cts_frmses">
            <label htmlFor="staticEmail" className="col-form-label">
              <i className="fa fa-info-circle" aria-hidden="true"></i>
              Fragile <span> * </span>
            </label>
            <select name="service" id="cars" className="form-control">
              <option value="">Select</option>
              <option value="">Most Recent</option>
              <option value="">Last Week</option>
              <option value="">Last Month</option>
              <option value="">Last Year</option>
            </select>
          </div>
        </div>

        <div className="form-group row">
          <div className="col-md-6 cts_frmses">
            <label htmlFor="staticEmail" className="col-form-label">
              <i className="fa fa-info-circle" aria-hidden="true"></i>
              Return Available <span> * </span>
            </label>
            <select name="service" id="cars" className="form-control">
              <option value="">Select</option>
              <option value="">Most Recent</option>
              <option value="">Last Week</option>
              <option value="">Last Month</option>
              <option value="">Last Year</option>
            </select>
          </div>
        </div>

        <div className="form-group row">
          <div className="col-md-6 cts_frmses">
            <label htmlFor="staticEmail" className="col-form-label">
              <i className="fa fa-info-circle" aria-hidden="true"></i>
              Exchange Available <span> * </span>
            </label>
            <select name="service" id="cars" className="form-control">
              <option value="">Select</option>
              <option value="">Most Recent</option>
              <option value="">Last Week</option>
              <option value="">Last Month</option>
              <option value="">Last Year</option>
            </select>
          </div>
        </div>

        <div className="form-group row">
          <div className="col-md-6 cts_frmses">
            <label htmlFor="staticEmail" className="col-form-label">
              <i className="fa fa-info-circle" aria-hidden="true"></i>
              Packaging dimensions <span> * </span>
            </label>
            <input type="text" className="form-control" name="" />
          </div>
          <div className="col-md-6 cts_frmses">
            <label htmlFor="staticEmail" className="col-form-label">
              <i className="fa fa-info-circle" aria-hidden="true"></i>
              Unit of Measurement (UOM) <span> * </span>
            </label>
            <select name="service" id="cars" className="form-control">
              <option value="">Select</option>
              <option value="">Most Recent</option>
              <option value="">Last Week</option>
              <option value="">Last Month</option>
              <option value="">Last Year</option>
            </select>
          </div>
        </div>

        <div className="col-md-9 frmses cats_fmsbtn">
          <input
            type="button"
            name=""
            className="edit44"
            value="Save & Go Back"
          />
          <input type="button" name="" className="edit44" value="Save & Next" />
          <input type="button" name="" className="edit44" value="Cancel" />
        </div>
      </form>
    </div>
  );
};

export default CatalogueDescription;
