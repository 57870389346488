import { invtImage } from '../../../constants/imageConstants';

export const inventoryData = [
  {
    category: 'Pooja Samagri',
    product: 'Agarbati',
    image: invtImage,
    hsn: '667546',
    partner_sku: 'ABC123',
    quantity: 102,
    unit_cost: 200,
    price: 150,
    restock: 40,
    restricted_product: 'Restricted product',
  },
  {
    category: 'hooja Samagri',
    product: 'Agarbati',
    image: invtImage,
    hsn: '667546',
    partner_sku: 'ABC123',
    quantity: 102,
    unit_cost: 200,
    price: 150,
    restock: 40,
    restricted_product: 'Restricted product',
  },
  {
    category: 'Pooja Samagri',
    product: 'Agarbati',
    image: invtImage,
    hsn: '667546',
    partner_sku: 'ABC123',
    quantity: 102,
    unit_cost: 200,
    price: 150,
    restock: 40,
    restricted_product: 'Restricted product',
  },
  {
    category: 'Pooja Samagri',
    product: 'Agarbati',
    image: invtImage,
    hsn: '667546',
    partner_sku: 'ABC123',
    quantity: 102,
    unit_cost: 200,
    price: 150,
    restock: 40,
    restricted_product: 'Restricted product',
  },
  {
    category: 'Pooja Samagri',
    product: 'Agarbati',
    image: invtImage,
    hsn: '667546',
    partner_sku: 'ABC123',
    quantity: 102,
    unit_cost: 200,
    price: 150,
    restock: 40,
    restricted_product: 'Restricted product',
  },
  {
    category: 'Pooja Samagri',
    product: 'Agarbati',
    image: invtImage,
    hsn: '667546',
    partner_sku: 'ABC123',
    quantity: 102,
    unit_cost: 200,
    price: 150,
    restock: 40,
    restricted_product: 'Restricted product',
  },
  {
    category: 'Pooja Samagri',
    product: 'Agarbati',
    image: invtImage,
    hsn: '667546',
    partner_sku: 'ABC123',
    quantity: 102,
    unit_cost: 200,
    price: 150,
    restock: 40,
    restricted_product: 'Restricted product',
  },
  {
    category: 'Pooja Samagri',
    product: 'Agarbati',
    image: invtImage,
    hsn: '667546',
    partner_sku: 'ABC123',
    quantity: 102,
    unit_cost: 200,
    price: 150,
    restock: 40,
    restricted_product: 'Restricted product',
  },
  {
    category: 'Pooja Samagri',
    product: 'Agarbati',
    image: invtImage,
    hsn: '667546',
    partner_sku: 'ABC123',
    quantity: 102,
    unit_cost: 200,
    price: 150,
    restock: 40,
    restricted_product: 'Restricted product',
  },
  {
    category: 'Pooja Samagri',
    product: 'Agarbati',
    image: invtImage,
    hsn: '667546',
    partner_sku: 'ABC123',
    quantity: 102,
    unit_cost: 200,
    price: 150,
    restock: 40,
    restricted_product: 'Restricted product',
  },
  {
    category: 'Pooja Samagri',
    product: 'Agarbati',
    image: invtImage,
    hsn: '667546',
    partner_sku: 'ABC123',
    quantity: 102,
    unit_cost: 200,
    price: 150,
    restock: 40,
    restricted_product: 'Restricted product',
  },
  {
    category: 'Pooja Samagri',
    product: 'Agarbati',
    image: invtImage,
    hsn: '667546',
    partner_sku: 'ABC123',
    quantity: 102,
    unit_cost: 200,
    price: 150,
    restock: 40,
    restricted_product: 'Restricted product',
  },
  {
    category: 'Pooja Samagri',
    product: 'Agarbati',
    image: invtImage,
    hsn: '667546',
    partner_sku: 'ABC123',
    quantity: 102,
    unit_cost: 200,
    price: 150,
    restock: 40,
    restricted_product: 'Restricted product',
  },
  {
    category: 'Pooja Samagri',
    product: 'Agarbati',
    image: invtImage,
    hsn: '667546',
    partner_sku: 'ABC123',
    quantity: 102,
    unit_cost: 200,
    price: 150,
    restock: 40,
    restricted_product: 'Restricted product',
  },
  {
    category: 'Pooja Samagri',
    product: 'Agarbati',
    image: invtImage,
    hsn: '667546',
    partner_sku: 'ABC123',
    quantity: 102,
    unit_cost: 200,
    price: 150,
    restock: 40,
    restricted_product: 'Restricted product',
  },
  {
    category: 'Pooja Samagri',
    product: 'Agarbati',
    image: invtImage,
    hsn: '667546',
    partner_sku: 'ABC123',
    quantity: 102,
    unit_cost: 200,
    price: 150,
    restock: 40,
    restricted_product: 'Restricted product',
  },
  {
    category: 'Pooja Samagri',
    product: 'Agarbati',
    image: invtImage,
    hsn: '667546',
    partner_sku: 'ABC123',
    quantity: 102,
    unit_cost: 200,
    price: 150,
    restock: 40,
    restricted_product: 'Restricted product',
  },
  {
    category: 'Pooja Samagri',
    product: 'Agarbati',
    image: invtImage,
    hsn: '667546',
    partner_sku: 'ABC123',
    quantity: 102,
    unit_cost: 200,
    price: 150,
    restock: 40,
    restricted_product: 'Restricted product',
  },
  {
    category: 'Pooja Samagri',
    product: 'Agarbati',
    image: invtImage,
    hsn: '667546',
    partner_sku: 'ABC123',
    quantity: 102,
    unit_cost: 200,
    price: 150,
    restock: 40,
    restricted_product: 'Restricted product',
  },
  {
    category: 'Pooja Samagri',
    product: 'Agarbati',
    image: invtImage,
    hsn: '667546',
    partner_sku: 'ABC123',
    quantity: 102,
    unit_cost: 200,
    price: 150,
    restock: 40,
    restricted_product: 'Restricted product',
  },
  {
    category: 'Pooja Samagri',
    product: 'Agarbati',
    image: invtImage,
    hsn: '667546',
    partner_sku: 'ABC123',
    quantity: 102,
    unit_cost: 200,
    price: 150,
    restock: 40,
    restricted_product: 'Restricted product',
  },
  {
    category: 'Pooja Samagri',
    product: 'Agarbati',
    image: invtImage,
    hsn: '667546',
    partner_sku: 'ABC123',
    quantity: 102,
    unit_cost: 200,
    price: 150,
    restock: 40,
    restricted_product: 'Restricted product',
  },
  {
    category: 'Pooja Samagri',
    product: 'Agarbati',
    image: invtImage,
    hsn: '667546',
    partner_sku: 'ABC123',
    quantity: 102,
    unit_cost: 200,
    price: 150,
    restock: 40,
    restricted_product: 'Restricted product',
  },
  {
    category: 'Pooja Samagri',
    product: 'Agarbati',
    image: invtImage,
    hsn: '667546',
    partner_sku: 'ABC123',
    quantity: 102,
    unit_cost: 200,
    price: 150,
    restock: 40,
    restricted_product: 'Restricted product',
  },
  {
    category: 'Pooja Samagri',
    product: 'Agarbati',
    image: invtImage,
    hsn: '667546',
    partner_sku: 'ABC123',
    quantity: 102,
    unit_cost: 200,
    price: 150,
    restock: 40,
    restricted_product: 'Restricted product',
  },
  {
    category: 'Pooja Samagri',
    product: 'Agarbati',
    image: invtImage,
    hsn: '667546',
    partner_sku: 'ABC123',
    quantity: 102,
    unit_cost: 200,
    price: 150,
    restock: 40,
    restricted_product: 'Restricted product',
  },
  {
    category: 'Pooja Samagri',
    product: 'Agarbati',
    image: invtImage,
    hsn: '667546',
    partner_sku: 'ABC123',
    quantity: 102,
    unit_cost: 200,
    price: 150,
    restock: 40,
    restricted_product: 'Restricted product',
  },
  {
    category: 'Pooja Samagri',
    product: 'Agarbati',
    image: invtImage,
    hsn: '667546',
    partner_sku: 'ABC123',
    quantity: 102,
    unit_cost: 200,
    price: 150,
    restock: 40,
    restricted_product: 'Restricted product',
  },
  {
    category: 'Pooja Samagri',
    product: 'Agarbati',
    image: invtImage,
    hsn: '667546',
    partner_sku: 'ABC123',
    quantity: 102,
    unit_cost: 200,
    price: 150,
    restock: 40,
    restricted_product: 'Restricted product',
  },
  {
    category: 'Pooja Samagri',
    product: 'Agarbati',
    image: invtImage,
    hsn: '667546',
    partner_sku: 'ABC123',
    quantity: 102,
    unit_cost: 200,
    price: 150,
    restock: 40,
    restricted_product: 'Restricted product',
  },
  {
    category: 'Pooja Samagri',
    product: 'Agarbati',
    image: invtImage,
    hsn: '667546',
    partner_sku: 'ABC123',
    quantity: 102,
    unit_cost: 200,
    price: 150,
    restock: 40,
    restricted_product: 'Restricted product',
  },
  {
    category: 'Pooja Samagri',
    product: 'Agarbati',
    image: invtImage,
    hsn: '667546',
    partner_sku: 'ABC123',
    quantity: 102,
    unit_cost: 200,
    price: 150,
    restock: 40,
    restricted_product: 'Restricted product',
  },
  {
    category: 'Pooja Samagri',
    product: 'Agarbati',
    image: invtImage,
    hsn: '667546',
    partner_sku: 'ABC123',
    quantity: 102,
    unit_cost: 200,
    price: 150,
    restock: 40,
    restricted_product: 'Restricted product',
  },
  {
    category: 'Pooja Samagri',
    product: 'Agarbati',
    image: invtImage,
    hsn: '667546',
    partner_sku: 'ABC123',
    quantity: 102,
    unit_cost: 200,
    price: 150,
    restock: 40,
    restricted_product: 'Restricted product',
  },
  {
    category: 'Pooja Samagri',
    product: 'Agarbati',
    image: invtImage,
    hsn: '667546',
    partner_sku: 'ABC123',
    quantity: 102,
    unit_cost: 200,
    price: 150,
    restock: 40,
    restricted_product: 'Restricted product',
  },
  {
    category: 'Pooja Samagri',
    product: 'Agarbati',
    image: invtImage,
    hsn: '667546',
    partner_sku: 'ABC123',
    quantity: 102,
    unit_cost: 200,
    price: 150,
    restock: 40,
    restricted_product: 'Restricted product',
  },
  {
    category: 'Pooja Samagri',
    product: 'Agarbati',
    image: invtImage,
    hsn: '667546',
    partner_sku: 'ABC123',
    quantity: 102,
    unit_cost: 200,
    price: 150,
    restock: 40,
    restricted_product: 'Restricted product',
  },
  {
    category: 'Pooja Samagri',
    product: 'Agarbati',
    image: invtImage,
    hsn: '667546',
    partner_sku: 'ABC123',
    quantity: 102,
    unit_cost: 200,
    price: 150,
    restock: 40,
    restricted_product: 'Restricted product',
  },
  {
    category: 'Pooja Samagri',
    product: 'Agarbati',
    image: invtImage,
    hsn: '667546',
    partner_sku: 'ABC123',
    quantity: 102,
    unit_cost: 200,
    price: 150,
    restock: 40,
    restricted_product: 'Restricted product',
  },
  {
    category: 'Pooja Samagri',
    product: 'Agarbati',
    image: invtImage,
    hsn: '667546',
    partner_sku: 'ABC123',
    quantity: 102,
    unit_cost: 200,
    price: 150,
    restock: 40,
    restricted_product: 'Restricted product',
  },
  {
    category: 'Pooja Samagri',
    product: 'Agarbati',
    image: invtImage,
    hsn: '667546',
    partner_sku: 'ABC123',
    quantity: 102,
    unit_cost: 200,
    price: 150,
    restock: 40,
    restricted_product: 'Restricted product',
  },
  {
    category: 'Pooja Samagri',
    product: 'Agarbati',
    image: invtImage,
    hsn: '667546',
    partner_sku: 'ABC123',
    quantity: 102,
    unit_cost: 200,
    price: 150,
    restock: 40,
    restricted_product: 'Restricted product',
  },
  {
    category: 'Pooja Samagri',
    product: 'Agarbati',
    image: invtImage,
    hsn: '667546',
    partner_sku: 'ABC123',
    quantity: 102,
    unit_cost: 200,
    price: 150,
    restock: 40,
    restricted_product: 'Restricted product',
  },
  {
    category: 'Pooja Samagri',
    product: 'Agarbati',
    image: invtImage,
    hsn: '667546',
    partner_sku: 'ABC123',
    quantity: 102,
    unit_cost: 200,
    price: 150,
    restock: 40,
    restricted_product: 'Restricted product',
  },
  {
    category: 'Pooja Samagri',
    product: 'Agarbati',
    image: invtImage,
    hsn: '667546',
    partner_sku: 'ABC123',
    quantity: 102,
    unit_cost: 200,
    price: 150,
    restock: 40,
    restricted_product: 'Restricted product',
  },
  {
    category: 'Pooja Samagri',
    product: 'Agarbati',
    image: invtImage,
    hsn: '667546',
    partner_sku: 'ABC123',
    quantity: 102,
    unit_cost: 200,
    price: 150,
    restock: 40,
    restricted_product: 'Restricted product',
  },
  {
    category: 'Pooja Samagri',
    product: 'Agarbati',
    image: invtImage,
    hsn: '667546',
    partner_sku: 'ABC123',
    quantity: 102,
    unit_cost: 200,
    price: 150,
    restock: 40,
    restricted_product: 'Restricted product',
  },
];
