import React, { useEffect, useState } from 'react';
import {
  downFlower,
  logoImage,
  upFlower,
} from '../../constants/imageConstants';
import { Link, useNavigate } from 'react-router-dom';
// import MainLinkButton from '../../components/buttons/MainLinkButton';
// import { ROUTER_URL_CONSTANT } from '../../constants/routerUriConstants';
import OTPInput from 'react-otp-input';
import { useFormik } from 'formik';
import * as yup from 'yup';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { getUserData } from '../../services/profile/getUserData';
import { decrypt } from '../../crypto';
import api from '../../api';
import { toast } from 'react-toastify';

const EmailVerify = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const uid = useSelector(state => state.profile.user.id);

  useEffect(() => {
    dispatch(getUserData());
  }, []);

  const [time, setTime] = useState(60);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const intervel = setInterval(() => {
      setTime(time => (time > 0 ? time - 1 : time));
    }, 1000);
    return () => clearInterval(intervel);
  }, []);

  const initialValues = {
    otp: '',
  };

  const validationSchema = yup.object({
    otp: yup.string().required("OTP can't be empty").min(4, 'Please enter valid OTP'),
  });

  const { values, errors, handleChange, handleSubmit, setFieldError } = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async () => {
      const { otp } = values;

      const { data } = await axios.get(`http://35.170.79.161:8080/api/user/userVerification/email/${otp}/${userId}`)
      console.log(data);

      if (data.statusCode === '200') {
        navigate('/email-verified');
      } else {
        toast.error('Invalid OTP', {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  });

  const sendOtp = () => {
    if (localStorage.getItem('userData')) {
      const userData = JSON.parse(decrypt(localStorage.getItem('userData')));
      console.log(userData);
      const { id: uid, userCode: userName } = userData;
      setUserId(uid);
      const params = {
        partnerType: "admin",
        userName,
        userType: "6",
      };

      api.post('/user/noAuth/sendOTPForLogin', params)
        .then(res => {
          res = res.data;
          if (res.status === 'SUCCESS') {
            toast.success('OTP sent successfully', {
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          } else {
            toast.error("Can't send OTP", {
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            console.log(res);
          }
        })
    } else {
      window.location.assign('/signin')
    }
  }

  useEffect(() => {
    sendOtp();
  }, [])

  return (
    <>
      <div className='flogs2'>
        <img src={downFlower} />
      </div>
      <div className='flogs1'>
        <img src={upFlower} />
      </div>
      <div className='logo'>
        <div className='row'>
          <div className='col-md-12'>
            <div className='log'>
              <Link to='/'><img src={logoImage} className='img-fluid' /> </Link>
            </div>
          </div>
        </div>
      </div>
      <div className='clearfix'></div>
      <div className='mob pt-md-5 pb-md-5 '>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>
              <div className='login-left lts'>
                <h2><span>Email Verification </span></h2>
                {/* <p>Enter OTP sent to   imavatar@gmail.com  <a href=''> Change</a></p> */}
                <div className='welcome-form'>
                  <form>
                    <div className="col-md-12 frmss">
                      <OTPInput
                        value={values.otp}
                        onChange={handleChange('otp')}
                        numInputs={4}
                        className="frmotp otp-input"
                        separator={<span style={{ width: '10px' }} />}
                        isInputNum={true}
                        containerStyle={{
                          justifyContent: 'center',
                        }}
                      />
                      {errors.otp && (<div className='err' style={{ marginTop: '10px !important' }}>{errors.otp}</div>)}
                    </div>
                    <div className='col-md-12 frmss mb-2'>
                      <label>Code expires in 00:{time}</label>
                    </div>
                    <div className='col-md-12 frmss mb-3'>
                      <p className='resend' onClick={sendOtp}>Resend OTP</p>
                    </div>

                    <div className='col-md-12 frmss mb-4'>
                      <input type='button' value='Confirm' className='btn btn1' onClick={() => handleSubmit()} />
                    </div>
                    {/* <div className='col-md-12 frmss mb-4'>
                      <a href='email_verified.html'> <input type='button' className='btn btn1' value='Next' /> </a>
                    </div> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailVerify;
