import React, { useEffect, useState } from 'react';
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useNavigate,
  useLocation
} from 'react-router-dom';
import {
  APP_CONSTANTS,
  ROUTER_URL_CONSTANT,
} from './constants/routerUriConstants';
import Home from './pages/home/Home';
import Signin from './pages/authentication/Signin';
import Signup from './pages/authentication/Signup';
import MobileVerify from './pages/authentication/MobileVerify';
import MobileVerified from './pages/authentication/MobileVerified';
import Sidebar from './layout/Sidebar/Sidebar';
import PersonalDetails from './pages/profile/PersonalDetails';
import ProfileWrapper from './pages/profile/ProfileWrapper';
import Dashboard from './pages/dashboard/Dashboard';
import BankDetails from './pages/profile/BankDetails';
import GstinDetails from './pages/profile/GstinDetails';
import { ManageUsers } from './pages/profile/ManageUsers';
import ChangePassword from './pages/profile/ChangePassword';
import LegalPolicies from './pages/profile/LegalPolicies';
import Notification from './pages/profile/Notification';
import ProtectedRoutes from './ProtectedRoutes';
import InventoryWrapper from './pages/inventory/InventoryWrapper';
import ActiveInventory from './pages/inventory/ActiveInventory';
import InventoryPending from './pages/inventory/InventoryPending';
import InventoryInactive from './pages/inventory/InventoryInactive';
import InventoryOnhold from './pages/inventory/InventoryOnhold';
import InventoryBlocked from './pages/inventory/InventoryBlocked';
import PaymentsWrapper from './pages/payments/PaymentsWrapper';
import OrdersWrapper from './pages/orders/OrdersWrapper';
import NewOrders from './pages/orders/NewOrders';
import ReadyToShipOrders from './pages/orders/ReadyToShipOrders';
import Intransit from './pages/orders/Intransit';
import Delivered from './pages/orders/Delivered';
import Cancelled from './pages/orders/Cancelled';
import ReportsWrapper from './pages/reports/ReportsWrapper';
import ReportOrders from './pages/reports/ReportOrders';
import ReportReturn from './pages/reports/ReportReturn';
import ReviewsAndRatings from './pages/reports/ReviewsAndRatings';
import CategoryWiseReviews from './pages/reports/CategoryWiseReviews';
import CatalougueWrapper from './pages/catalogue/CatalougueWrapper';
import CategoryUploads from './pages/catalogue/CategoryUploads';
import SelectCategory from './pages/catalogue/SelectCategory';
import AddCatalogueProduct from './pages/catalogue/AddCatalogueProduct';
import ProductVitalInfo from './pages/catalogue/ProductVitalInfo';
import CatalogPricing from './pages/catalogue/CatalogPricing';
import CatalogueDescription from './pages/catalogue/CatalogueDescription';
import ImagesOrVideos from './pages/catalogue/ImagesOrVideos';
import ReturnWrapper from './pages/return/ReturnWrapper';
import ReturnInitiated from './pages/return/ReturnInitiated';
import ReturnToday from './pages/return/ReturnToday';
import ReturnCompleted from './pages/return/ReturnCompleted';
import ReturnExchange from './pages/return/ReturnExchange';
import { ToastContainer } from 'react-toastify';
import { getItem } from './utils/storage';
import EmailVerify from './pages/authentication/EmailVerify';
import EmailVerified from './pages/authentication/EmailVerified';

function App() {
  const userData = getItem(APP_CONSTANTS.auth_token);
  // const navigate = useNavigate();
  // const location = useLocation();

  useEffect(() => {
    // console.log(location);

    const path = window.location.href.split('/')[3];

    if (!localStorage.getItem('userData') && path !== 'signin' && path !== 'signup' && path !== 'mobileotp' && path !== 'mobileverified' && path !== 'email-verify' && path !== 'email-verified') {
      window.location.assign('/signin');
    } else if (localStorage.getItem('userData') && (path === 'signin' || path === 'signup' || path === 'mobileotp' || path === 'mobileverified' || path === 'email-verify' || path === 'email-verified')) {
      window.location.assign('/profile/details');
    }
  }, []);
  return (
    <>
      <ToastContainer />
      <BrowserRouter>
        <Routes>
          <Route path={ROUTER_URL_CONSTANT.SIGNUP} element={<Signup />} />
          <Route path={ROUTER_URL_CONSTANT.SIGNIN} element={<Signin />} />
          <Route
            path={ROUTER_URL_CONSTANT.MOBILEOTP_VERIFY}
            element={<MobileVerify />}
          />
          <Route
            path={ROUTER_URL_CONSTANT.MOBILEOTP_VERIFIED}
            element={<MobileVerified />}
          />
          <Route
            path={ROUTER_URL_CONSTANT.EMAILVERIFY}
            element={<EmailVerify />}
          />
          <Route
            path={ROUTER_URL_CONSTANT.EMAILVERIFIED}
            element={<EmailVerified />}
          />
          <Route element={<ProtectedRoutes />}>
            <Route path={`${ROUTER_URL_CONSTANT.HOME}/*`} element={<Sidebar />}>
              <Route
                path={ROUTER_URL_CONSTANT.PAYMENYS}
                element={<PaymentsWrapper />}
              />

              {/* INVENTORY ROUTES */}
              <Route
                path={`${ROUTER_URL_CONSTANT.INVENTORY}`}
                element={<InventoryWrapper />}
              >
                <Route
                  path={ROUTER_URL_CONSTANT.INVENTORY_ACTIVE}
                  element={<ActiveInventory />}
                />
                <Route
                  path={ROUTER_URL_CONSTANT.INVENTORY_PENDING}
                  element={<InventoryPending />}
                />
                <Route
                  path={ROUTER_URL_CONSTANT.INVENTORY_INACTIVE}
                  element={<InventoryInactive />}
                />
                <Route
                  path={ROUTER_URL_CONSTANT.INVENTORY_ONHOLD}
                  element={<InventoryOnhold />}
                />
                <Route
                  path={ROUTER_URL_CONSTANT.INVENTORY_BLOCKED}
                  element={<InventoryBlocked />}
                />
              </Route>

              {/* ORDERS ROUTE */}
              <Route
                path={ROUTER_URL_CONSTANT.ORDERS}
                element={<OrdersWrapper />}
              >
                <Route
                  path={ROUTER_URL_CONSTANT.NEW_ORDER}
                  element={<NewOrders />}
                />
                <Route
                  path={ROUTER_URL_CONSTANT.READY_TO_SHIP}
                  element={<ReadyToShipOrders />}
                />
                <Route
                  path={ROUTER_URL_CONSTANT.INTRANSIT}
                  element={<Intransit />}
                />
                <Route
                  path={ROUTER_URL_CONSTANT.DELIVERED}
                  element={<Delivered />}
                />
                <Route
                  path={ROUTER_URL_CONSTANT.CANCELLED}
                  element={<Cancelled />}
                />
              </Route>

              {/* CATALOGUE ROUTE */}
              <Route
                path={ROUTER_URL_CONSTANT.CATELOGS}
                element={<CategoryUploads />}
              />
              <Route
                path={ROUTER_URL_CONSTANT.CATALOG_SELECT_CATEGORY}
                element={<SelectCategory />}
              />
              <Route
                path={ROUTER_URL_CONSTANT.CATALOGUE_PRODUCT}
                element={<AddCatalogueProduct />}
              >
                <Route
                  path={ROUTER_URL_CONSTANT.CATALOGUE_PRODUCT_INFO}
                  element={<ProductVitalInfo />}
                />
                <Route
                  path={ROUTER_URL_CONSTANT.CATALOGUE_PRICING}
                  element={<CatalogPricing />}
                />
                <Route
                  path={ROUTER_URL_CONSTANT.CATALOGUE_DESCRIPTION}
                  element={<CatalogueDescription />}
                />
                <Route
                  path={ROUTER_URL_CONSTANT.CATALOGUE_IMAGES_VIDEOS}
                  element={<ImagesOrVideos />}
                />
              </Route>
              {/* CATALOGUE ROUTE END */}

              {/* PROFILE ROUTES */}
              <Route
                path={`${ROUTER_URL_CONSTANT.PROFILE}/*`}
                element={<ProfileWrapper />}
              >
                <Route
                  path={ROUTER_URL_CONSTANT.PROFILE_DETAILS}
                  element={<PersonalDetails />}
                />
                <Route
                  path={ROUTER_URL_CONSTANT.BANK_DETAILS}
                  element={<BankDetails />}
                />
                <Route
                  path={ROUTER_URL_CONSTANT.GSTIN_DETAILS}
                  element={<GstinDetails />}
                />
                <Route
                  path={ROUTER_URL_CONSTANT.MANAGE_USERS}
                  element={<ManageUsers />}
                />
                <Route
                  path={ROUTER_URL_CONSTANT.CHANGE_PASSWORD}
                  element={<ChangePassword />}
                />
                <Route
                  path={ROUTER_URL_CONSTANT.POLICIES}
                  element={<LegalPolicies />}
                />
                <Route
                  path={ROUTER_URL_CONSTANT.WHATSAPP_NOTIFICATION}
                  element={<Notification />}
                />
              </Route>
              {/* PROFILE ROUTES END */}

              {/* REPORTS ROUTE */}
              <Route
                path={ROUTER_URL_CONSTANT.REPORTS}
                element={<ReportsWrapper />}
              >
                <Route
                  path={ROUTER_URL_CONSTANT.REPORT_ORDERS}
                  element={<ReportOrders />}
                />
                <Route
                  path={ROUTER_URL_CONSTANT.REPORT_RETURNS}
                  element={<ReportReturn />}
                />
                <Route
                  path={ROUTER_URL_CONSTANT.REPORT_REVIEWS_RATINGS}
                  element={<ReviewsAndRatings />}
                />
                <Route
                  path={ROUTER_URL_CONSTANT.REPORT_CATEGORY_WISE}
                  element={<CategoryWiseReviews />}
                />
              </Route>
              {/* REPORTS ROUTE END */}

              {/* RETURN ROUTES */}
              <Route
                path={ROUTER_URL_CONSTANT.RETURN}
                element={<ReturnWrapper />}
              >
                <Route
                  path={ROUTER_URL_CONSTANT.INITIATED}
                  element={<ReturnInitiated />}
                />
                <Route
                  path={ROUTER_URL_CONSTANT.TODAY}
                  element={<ReturnToday />}
                />
                <Route
                  path={ROUTER_URL_CONSTANT.COMPLETED}
                  element={<ReturnCompleted />}
                />
                <Route
                  path={ROUTER_URL_CONSTANT.EXCHANGE}
                  element={<ReturnExchange />}
                />
              </Route>
              {/* RETURN ROUTES END */}
              <Route path="dashboard" element={<Dashboard />} />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
