import React from 'react';

const LegalPolicies = () => {
  return (
    <div className="panel_form">
      <h3 className="col-md-12">Legal terms & Policies</h3>
      <div className="legal_data">
        <p>Partner Agreement</p>
        <p>
          <a href="">Download</a>
        </p>
      </div>
      <div className="legal_data">
        <p>Privacy Policy</p>
        <p>
          <a href="">Download</a>
        </p>
      </div>
      <div className="legal_data">
        <p>Terms and Condition</p>
        <p>
          <a href="">Download</a>
        </p>
      </div>
      <div className="legal_data">
        <p>Partner Deactivation Policy</p>
        <p>
          <a href="">Download</a>
        </p>
      </div>
      <div className="legal_data">
        <p>Prohibited and Restricted Product List</p>
        <p>
          <a href="">Download</a>
        </p>
      </div>
      <div className="legal_data">
        <p>Delivery & Return Policy</p>
        <p>
          <a href="">Download</a>
        </p>
      </div>
    </div>
  );
};

export default LegalPolicies;
